/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { useAccount } from 'wagmi'
import { readContract } from '@wagmi/core'
import '../App.css'
import MultiCallAbi from '../config/MultiCallAbi.json'
import '../styles/MainContainer.css'
import TopBar from '../components/TopBar.jsx'
import CustomRadioButton from '../components/CustomRadioButton.jsx'
import ClipLoader from 'react-spinners/ClipLoader'
import { StringParam, useQueryParam } from 'use-query-params'
import TradeCard from '../components/TradeCard.jsx'
import BalanceCard from '../components/BalanceCard.jsx'
import LaunchpadCard from '../components/LaunchpadCard.jsx'
import { imageUrl, apiUrl, ethPriceApiUrl, supportedChainIds, chainLogos, scanLinks, coinNames, projectName } from '../utils/constants.ts'
import { getMulticallAddress } from '../utils/addressHelpers.ts'
import rot13 from '../../utils/encode.ts'
import CopyIcon from '../icons/copy.svg'

const Profile = () => {
  let [profileAddress] = useQueryParam('address', StringParam)
  const { address } = useAccount()
  if (!profileAddress)
    profileAddress = address;
  const [, setUserName] = useState('@hotkey')
  const [, setUserTelegram] = useState('')
  const [, setUserX] = useState('')
  const [, setUserWebsite] = useState('')

  let [loading, setLoading] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [fetched1, setFetched1] = useState(false)
  const [, setUserAvatarUrl] = useState('/img/moonboy67.png')

  const [selectedOption, setSelectedOption] = useState('Balances')
  const handleSelect = value => setSelectedOption(value)
  const [, setTotalLength] = useState(0)
  const [chadLists, setChadListData] = useState([])
  const [chadBalances, setChadBalances] = useState([])
  const [history, setHistory] = useState([{ amount: 0, name: '', token: '', type: '', date: '' }])
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)


  useEffect(() => {
    const FetchDeployedData = async () => {
      try {
        setLoading(true)

        for (const chainId of supportedChainIds) {
          const ethPriceResponse = await fetch(ethPriceApiUrl[chainId], { method: 'GET' });
          const ethPriceData = await ethPriceResponse.json();
          const ethPrice = ethPriceData.USD;

          let mainInfo = await readContract({
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getUserDeployMainInfo',
            args: [profileAddress],
            chainId: chainId
          })
          let otherInfo = await readContract({
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getUserDeployOtherInfo',
            args: [profileAddress],
            chainId: chainId
          })
          const restInfo = await readContract({
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getRestInfo',
            chainId: chainId
          })

          if (mainInfo && otherInfo) {
            setTotalLength(mainInfo[0].length)
            if (mainInfo[0].length > 0) {
              for (let i = mainInfo[0].length - 1; i >= 0; i--) {
                const contractAddress = mainInfo[4][i]
                const virtualLpAmounts = Number(mainInfo[1][i]) * ethPrice
                const tokenPrice = Number(mainInfo[2][i])
                const marketCap = (tokenPrice * 1000000000 * Number(ethPrice)) / 10 ** 12
                const website = otherInfo[2][i]
                const twitter = otherInfo[3][i]
                const telegram = otherInfo[4][i]
                const progress = marketCap * 100 / 69000
                const liquidity = virtualLpAmounts
                const name = otherInfo[0][i]
                let logoUrl = imageUrl + projectName + "-" + chainId + "-" + contractAddress + '-logo.png'
                let bannerUrl = imageUrl + projectName + "-" + chainId + "-" + contractAddress + '-banner.png'
                let blockchainLogoUrl = chainLogos[chainId]
                let devAddress = mainInfo[5][i]
                let dexAddress = 'https://panakeswap.finance/swap'
                let dexName = 'Pancakeswap'
                const description = restInfo[2][i]
                const chadData = {
                  chainId: chainId,
                  progress: progress,
                  Liquidity: liquidity,
                  tokenName: name,
                  tokenDescription: description,
                  logoUrl: logoUrl,
                  bannerUrl: bannerUrl,
                  address: mainInfo[4][i],
                  depositedAmount: Number(mainInfo[3][i]) * ethPrice / 10 ** 18,
                  contractAddress: contractAddress,
                  dexAddress: dexAddress,
                  devAddress: devAddress,
                  dexName: dexName,
                  marketCap: marketCap,
                  website: website,
                  twitter: twitter,
                  telegram: telegram,
                  blockchainLogoUrl: blockchainLogoUrl
                }
                setChadListData(prevState => [...prevState, chadData])
              }
            }
          }
        }
        setLoading(false)
        setFetched(true)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    const FetchBalances = async () => {
      try {
        setLoading(true)
        for (const chainId of supportedChainIds) {
          let mainInfo = await readContract({
            address: getMulticallAddress(chainId),
            abi: MultiCallAbi,
            functionName: 'getUserBalance',
            args: [profileAddress],
            chainId: chainId
          })

          if (mainInfo) {
            setTotalLength(mainInfo[0].length)
            if (mainInfo[0].length > 0) {
              for (let i = mainInfo[0].length - 1; i >= 0; i--) {
                const name = mainInfo[0][i]
                const symbol = mainInfo[1][i]
                const tokenAddress = mainInfo[2][i]
                const balance = Number(mainInfo[3][i]) / (10 ** 18)
                let logoUrl = imageUrl + projectName + "-" + chainId + "-" + tokenAddress + '-logo.png'
                const chadData = {
                  chainId,
                  name: name,
                  symbol: symbol,
                  tokenAddress: tokenAddress,
                  logoUrl: logoUrl,
                  balance: balance
                }
                setChadBalances(prev => [...prev, chadData])
              }
            }
          }
        }
        setLoading(false)
        setFetched1(true)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }

    const FetchTrades = async () => {
      try {
        await fetch(
          apiUrl + `/api/getUserHistory/${profileAddress}`,
          {
            method: 'GET'
          }
        ).then(async res => {
          let data = await res.json()
          if (data.length > 0) {
            let history = {}
            let historyData: any[] = []
            for (let i = 0; i < data?.length; i++) {
              let chainId = data[i].chainId
              let amount = data[i].amount
              let buyer = data[i].buyer
              let name = data[i].name
              let token = data[i].token
              let type = data[i].type
              let contract = data[i].contract
              let currentDate = Date.now()
              let timestamp = currentDate / 1000 - Number(data[i].timestamp)
              let date
              if (timestamp > 86400) {
                date = (timestamp / 86400).toFixed(0) + ' days ago'
              } else if (timestamp > 3600) {
                date = (timestamp / 3600).toFixed(0) + ' hours ago'
              } else if (timestamp > 0) {
                date = (timestamp / 60).toFixed(0) + ' mins ago'
              } else {
                date = ' just now'
              }
              history = { chainId, amount: amount, buyer: buyer, name: name, token: token, type: type, contract: contract, date: date }
              historyData.push(history)
            }
            setHistory(historyData)
          } else {
            setHistory([{ amount: 0, name: '', token: '', type: '', date: '' }])
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    if (selectedOption === 'Deploys' && !fetched) {
      FetchDeployedData()
    } else if (selectedOption === 'Balances' && !fetched1) {
      FetchBalances()
    } else if (selectedOption === 'Trades') {
      FetchTrades()
    }
  }, [selectedOption, profileAddress])

  useEffect(() => {
    const FetchInfo = async () => {
      try {
        setUserAvatarUrl(imageUrl + `profile-${profileAddress}.png`)
        await fetch(apiUrl + `/api/getProfile/${profileAddress}`, {
          method: 'GET'
        })
          .then(async res => {
            let data = await res.json()
            if (data.length > 0) {
              setUserName(data[0].name)
              setUserTelegram(data[0].telegram)
              setUserX(data[0].twitter)
              setUserWebsite(data[0].website)
            } else {
              setUserName('@HOTKEY')
            }
          })
      } catch (e) {
        console.error(e)
      }
    }
    if (profileAddress) {
      FetchInfo()
    }
  }, [profileAddress, address])


  const BASE_URL = 'https://meme-base.fun'
  const referlink = address ? `${BASE_URL}/?ref=${rot13(address)}` : `${BASE_URL}/?ref=`

  const copyAddress = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  function displayTooltip() {
    let timeoutId
    setIsTooltipDisplayed(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className='max-w-7xl m-auto pt-48 pb-24 px-4 sm:px-12 sm:py-10'>
            {/* <div
              className="profile-card"
              style={{
                backgroundImage: `url(${userBannerUrl})`,
                backgroundSize: 'auto 192px',
                backgroundPosition: 'center top',
                backgroundRepeat: 'no-repeat',
                overflowX: 'hidden'
              }}
            >
              <div className="launchpad-eth-logo-container relative">
                <img src={userAvatarUrl} className="profile-avatar"
                  onError={event => {
                    event.target.src = "/img/moonboy67.png"
                    event.onerror = null
                  }} />
              </div>
              <div style={{ paddingTop: '72px' }} />
              <p className="profile-name absolute">
                {userName}{' '}
                {profileAddress === address && address && (
                  <button
                    onClick={handleEditIconClick}
                    style={{ all: 'unset', cursor: 'pointer' }}
                  >
                    <EditIcon className="profile-edit" />
                  </button>
                )}
              </p>
            </div> */}
            <div className='flex flex-col md:flex-row gap-6'>
              <section className="ProfileBox mt-8 grow">
                <div className="custom-radio-button-wrapper">
                  <CustomRadioButton
                    value="Balances"
                    selectedValue={selectedOption}
                    handleSelect={handleSelect}
                  />
                  <CustomRadioButton
                    value="Trades"
                    selectedValue={selectedOption}
                    handleSelect={handleSelect}
                  />
                  <CustomRadioButton
                    value="Deploys"
                    selectedValue={selectedOption}
                    handleSelect={handleSelect}
                  />
                  {/* {profileAddress === address && address ?
                    <CustomRadioButton
                      value="Details"
                      selectedValue={selectedOption}
                      handleSelect={handleSelect}
                    /> :
                    <></>
                  } */}
                </div>
                {/* {selectedOption === 'Details' && address === profileAddress && (
                  <>
                    <div className="profile-card bg-[#0c0d0f] rounded-[25px]">
                      <div className="user-details-wrapper w-full sm:w-[80%]">
                        <div className='flex flex-row gap-4 sm:gap-0 items-center w-full'>
                          <div className='flex justify-center items-center w-[30%]'>
                            <div className="custom-file-input-container w-[100px] h-[100px]">
                              <div className="profile-user-avatar">
                                <LogoImageUpload
                                  onChange={handleImageLogoChange}
                                  className=""
                                  style={undefined}
                                />
                              </div>
                            </div>

                          </div>

                          <div className='flex flex-col gap-4 w-[70%]'>
                            <div className="profile-title">
                              <p>Username</p>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={userName}
                                className="profile-text-input w-full"
                                onChange={e => setUserName(e.target.value)}
                              />
                            </div>

                          </div>

                        </div>

                        <div className='flex flex-col gap-4 w-full py-4'>
                          <div className='flex flex-col gap-4 w-full'>
                            <div className="profile-title">
                              <p>Telegram</p>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={userTelegram}
                                className="profile-text-input w-full"
                                placeholder='https://'
                                onChange={e => setUserTelegram(e.target.value)}
                              />
                            </div>

                          </div>


                          <div className='flex flex-col gap-4 w-full'>
                            <div className="profile-title">
                              <p>X.com Link</p>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={userX}
                                className="profile-text-input w-full"
                                placeholder='https://'
                                onChange={e => setUserX(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className='flex flex-col gap-4 w-full'>
                            <div className="profile-title">
                              <p>Website Link</p>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={userWebsite}
                                className="profile-text-input w-full"
                                placeholder='https://'
                                onChange={e => setUserWebsite(e.target.value)}
                              />
                            </div>

                          </div>

                        </div>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <button className="save-button w-24 h-12 justify-center" onClick={onSave}>
                          {!loading ?
                            'Save'
                            :
                            <ClipLoader
                              color={'#222'}
                              loading={loading}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          }
                        </button>
                      </div>
                    </div>
                    <br />
                  </>
                )} */}
                {selectedOption === 'Balances' ?
                  <>
                    {chadBalances.length > 0 ?
                      <>
                        {!loading && chadBalances.map(({ chainId, name, symbol, tokenAddress, logoUrl, balance }, i) => (
                          <div key={i}>
                            <BalanceCard
                              chainId={chainId}
                              chainLogo={chainLogos[chainId]}
                              name={name}
                              symbol={symbol}
                              tokenAddress={tokenAddress}
                              Logo={
                                <img
                                  src={logoUrl}
                                  className="balance-token-logo"
                                />
                              }
                              balance={balance}
                            />
                          </div>
                        )
                        )}
                        {loading && (
                          <div className="flex flex-col gap-2 w-full items-center">
                            <p className="Text1" style={{ color: 'white' }}>
                              Loading...
                            </p>
                            <ClipLoader
                              color={'#afccc6'}
                              loading={true}
                              size={50}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>)}
                      </>
                      :
                      <>
                        <div className='text-white flex justify-center'>No Data</div>
                      </>}
                  </>
                  :
                  <></>
                }
                {selectedOption === 'Trades' ?
                  <>
                    <div className='trade-box'>
                      <div className='balanceContent'>
                        <div className="trade-header-container py-2">
                          <p className="Balance-token-name left-aligned">Token</p>
                          <p className="Balance-token-name left-aligned">Type</p>
                          <p className="Balance-token-name left-aligned">Amount</p>
                          <p className="Balance-token-name left-aligned">Date</p>
                        </div>
                      </div>

                    </div>
                    {history[0].name !== '' ?
                      history.map(({ chainId, amount, name, token, type, date }, i) => (
                        <TradeCard
                          name={name}
                          amount={amount}
                          token={token}
                          type={type}
                          date={date}
                          scanLink={scanLinks[chainId]}
                          coinName={coinNames[chainId]}
                        />
                      )
                      ) :
                      <><div className='trades-box py-3 text-sm text-white flex justify-center'>No Data</div></>}

                  </>
                  :
                  <></>
                }
                {selectedOption === 'Deploys' ?
                  <>
                    <div className="launchpad-card-grid">
                      {!loading && chadLists.length > 0 && chadLists.map(({
                        chainId,
                        progress,
                        Liquidity,
                        tokenName,
                        tokenDescription,
                        logoUrl,
                        bannerUrl,
                        address,
                        depositedAmount,
                        contractAddress,
                        dexAddress,
                        devAddress,
                        dexName,
                        marketCap,
                        website,
                        twitter,
                        telegram,
                        blockchainLogoUrl
                      }, i) => (
                        tokenName !== '' ?
                          <LaunchpadCard
                            chainId={chainId}
                            progress={progress}
                            Liquidity={Liquidity}
                            tokenName={tokenName}
                            tokenDescription={tokenDescription}
                            Logo={<img src={logoUrl} className="claim-card-logo" />}
                            Banner={bannerUrl}
                            chadAddress={address}
                            depositedAmount={depositedAmount}
                            contractAddress={contractAddress}
                            dexAddress={dexAddress}
                            devAddress={devAddress}
                            dexName={dexName}
                            marketCap={marketCap}
                            website={website}
                            twitter={twitter}
                            telegram={telegram}
                            BlockchainLogo={
                              <img
                                src={blockchainLogoUrl}
                                className="launchpad-blockchain-logo"
                              />
                            }
                          />
                          :
                          <></>

                      )
                      )
                      }
                    </div>
                    {!loading && chadLists.length === 0 && (
                      <div className='text-white flex justify-center'>No Data</div>
                    )}
                    {loading && (
                      <div className="flex flex-col gap-2 w-full items-center">
                        <p className="Text1" style={{ color: 'white' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={true}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>)}
                  </>
                  :
                  <></>
                }
              </section>
              <div className={`${selectedOption === 'Deploys' ? 'hidden' : 'flex'} h-fit flex-col bg-[#0c0d0f] rounded-[16px] p-6 w-full md:w-[360px] my-[65px] border border-[#1a81da]`}>
                <div className="token-info-item">
                  <span className="token-info-label mx-auto">
                    <h3 className='text-white font-bold text-[24px]' style={{ marginTop: '0px' }}>
                      Earn <span className='text-[#7db4e5]'>0.5%</span> of each trade
                    </h3>
                  </span>
                </div>
                <div className="token-info-item mt-2">
                  <span className="token-info-label font-light">
                    Share referral link with your friends and earn 0.5% of every
                    trade they make.
                  </span>
                </div>
                <br />
                <div className='bg-[#010101] flex w-full items-center gap-2 rounded-[8px] py-[14px] pr-[8px] pl-[16px]'>
                  <span className='text-[16px] text-white font-semibold w-[280px] truncate'>{isTooltipDisplayed ? "Copied" : referlink}</span>
                  <button className='flex justify-center items-center w-12 h-12 rounded-[8px] bg-[#283c60] hover:bg-[#4a5e82]' onClick={copyAddress(referlink)}>
                    <img src={CopyIcon} />
                  </button>
                </div>
              </div>
            </div>
            {/* <div
              className="grid xl:grid-cols-1 gap-4"
              style={{ marginTop: '20px' }}
            >
              <div className="flex flex-col gap-3">
                <div
                  className="flex flex-col-reverse lg:flex-row bg-[#FFC000] rounded-[40px] lg:px-8 px-2.5 py-5"
                  style={{
                    boxShadow: 'rgb(103, 103, 103) 0px 5px 10px 0px',
                    border: '2px solid white',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-around'
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <div
                      className="text-xl mb-1.5 refer-heading"
                      style={{
                        width: '80%',
                        textAlign: 'center',
                        fontSize: '35px',
                        fontWeight: 'black',
                        textTransform: 'uppercase',
                        fontWeight: 'bold'
                      }}
                    >
                      Refer HOTKEY to your friends and start earning
                    </div>
                    <div
                      className="Text1"
                      style={{
                        width: '100%',
                        fontSize: '15px',
                        color: 'black'
                      }}
                    >
                      Introduce your friends to a better way to trade. Refer
                      them to Blackoump.fun, and you will get{' '}
                      <span className='fees-bold'>
                        50% of the fees
                      </span>
                    </div>
                  </div>
                  <div>
                    <img style={{ height: '180px' }} src={footericon} />
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div >
  )
}

export default Profile
