/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import WebsiteIcon from '../icons/website.svg'
import TelegramIcon from '../icons/telegram.svg'
import TwitterIcon from '../icons/x-icon.svg'
import CopyIcon from '../icons/copy.svg'

const SocialSection = ({ website, telegram, twitter }) => (
  <div
    className="buy-social-section"
    style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
  >
    {twitter && (
      <a href={`${twitter}`} target="_blank" rel="noopener noreferrer">
        <img src={TwitterIcon} alt="Twitter" className="social-icon" />
      </a>
    )}
    {telegram && (
      <a href={`${telegram}`} target="_blank" rel="noopener noreferrer">
        <img src={TelegramIcon} alt="Telegram" className="social-icon" />
      </a>
    )}
    {website && (
      <a href={website} target="_blank" rel="noopener noreferrer">
        <img src={WebsiteIcon} alt="Website" className="social-icon" />
      </a>
    )}
  </div>
)

const ChadPumpInfoSection = ({
  tokenSupplyUSD,
  tokenSupplyLiquidity,
  marketCap,
  tokenUnsoldTokens,
  maxBuyAmount,
  volume
}) => (
  <>
    <div className="fields flex justify-between">
      <div className="flex flex-col gap-1">
        <span className="font-semibold text-[#999999] text-[12px]">MCAP</span>
        <span className="font-bold text-white text-[20px]">
          ${new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(
            marketCap
          )}
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-semibold text-[#999999] text-[12px]">TVL</span>
        <span className="font-bold text-white text-[20px]">
          ${tokenSupplyUSD.toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-semibold text-[#999999] text-[12px]">Volume</span>
        <span className="font-bold text-white text-[20px]">
          ${(volume / (10 ** 18)).toLocaleString(undefined, { maximumFractionDigits: 0 })}
        </span>
      </div>
    </div>
  </>
)

const ClaimCard = ({
  tokenName,
  Logo,
  tokenAddress,
  contractAddress,
  dexAddress,
  devAddress,
  dexName,
  tokenSymbol,
  maxLimit,
  tokenDecimals,
  tokenTotalSupply,
  maxBuyAmount,
  tokenSupplyUSD,
  tokenSupplyLiquidity,
  tokenPrice,
  tokenUnsoldTokens,
  tokenCover,
  website,
  telegram,
  twitter,
  volume,
  description,
  ethPrice,
  lpCreated
}) => {
  const [bannerUrl, setBannerUrl] = useState();
  const [logoUrl, setLogoUrl] = useState();
  checkImageUrl(tokenCover);
  function checkImageUrl(url) {
    const img = new Image();
    img.onload = function () {
      setBannerUrl(tokenCover)
    };
    img.onerror = function () {
      setBannerUrl(false)
    };
    img.src = url; // Initiate the image loading
  }
  checkLogoUrl(Logo.props.src);
  function checkLogoUrl(url) {
    const img = new Image();
    img.onload = function () {
      setLogoUrl(Logo)
    };
    img.onerror = function () {
      setLogoUrl(false)
    };
    img.src = url; // Initiate the image loading
  }
  if (website && !website?.includes("https")) {
    website = "https://".concat(website)
  }
  if (telegram && !telegram?.includes("https")) {
    if (telegram?.includes("@")) {
      telegram = telegram.replace('@', '')
      telegram = "https://t.me/".concat(telegram)
    } else {
      telegram = "https://t.me/".concat(telegram)
    }
  }
  if (twitter && !twitter?.includes("https")) {
    twitter = "https://x.com/".concat(twitter)
  }
  let maxLimitAmount = (maxLimit * 1000000000 / 100).toLocaleString();
  let marketCap = (tokenPrice * 1000000000 * Number(ethPrice)) / 10 ** 12
  let progress = marketCap * 100 / 69000
  if (lpCreated) {
    progress = 100
  }

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  const copyAddress = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  function displayTooltip() {
    let timeoutId
    setIsTooltipDisplayed(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }

  return (
    <div className="relative overflow-hidden">
      <div className='relative flex flex-row items-center justify-center aspect-w-[208] w-full aspect-h-[85] overflow-hidden aspect-video z-1'>
        {!bannerUrl === false ?
          <img src={tokenCover} sizes='100vw' width={208} height={85} className='object-cover object-center' />
          :
          <div className='bg-[black]'></div>}
      </div>
      <div className='flex flex-col relative rounded-[25px] mt-[-42px] bg-[#0c0d0f] z-2 !rounded-b-none p-6 gap-[16px]'>
        <div className='flex justify-between gap-4'>

          <div className="flex gap-4 flex-grow">
            {!logoUrl === false ?
              Logo
              :
              <div className='bg-[black]'></div>
            }
            <div className="flex flex-col gap-0">
              <span className="text-white font-bold text-[20px]">{tokenSymbol}</span>
              <span className="font-semibold text-[#919191] text-[16px]">{tokenName}</span>
            </div>
          </div>
          <SocialSection website={website} telegram={telegram} twitter={twitter} />
        </div>

        <div className=' font-light text-white text-[14px]'>
          {description}
        </div>
        <div className=' font-light text-white text-[14px]'>
          Max Limit : {maxLimit}% ({maxLimitAmount} {tokenSymbol})
        </div>
        <div className='claim-token-content-box'>
          <p className='text-[14px]'>
            {' '}
            Token : &nbsp;&nbsp;&nbsp;
            <button onClick={copyAddress(tokenAddress)} className="CopyButton">
              <p className="launchpad-cap-type">
                {isTooltipDisplayed ? (
                  <span className="tooltiptext">Copied!</span>
                ) : (
                  <>
                    {tokenAddress.slice(0, 5) + '..' + tokenAddress.slice(-3)}
                    <img
                      src={CopyIcon}
                      alt=""
                      width="16px"
                      height="16px"
                      style={{ marginLeft: '4px', marginBottom: '-4px' }}
                    />
                  </>
                )}
              </p>
            </button>
          </p>
        </div>

        <div className="launchpad-progress-container bg-[#010101] p-4 rounded-[16px] flex flex-col gap-6">
          <div className="h-6">
            <div className="relative w-full h-full bg-[#00f3ef17] rounded-[16px]">
              <div className=" items-center overflow-hidden rounded-[16px] launchpad-progress-bar-filled h-full" style={{ width: `${progress}%` }}></div>
              <span className="select-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[16px]">
                {progress.toFixed(3)}%
              </span>
            </div>
          </div>

          <ChadPumpInfoSection
            tokenSupplyLiquidity={tokenSupplyLiquidity}
            marketCap={tokenSupplyUSD / tokenSupplyLiquidity / 2 * 10 ** 9}
            tokenSupplyUSD={tokenSupplyUSD}
            tokenUnsoldTokens={tokenUnsoldTokens}
            maxBuyAmount={maxBuyAmount}
            volume={volume}
          />
        </div>

      </div>
    </div>
  )
}

ClaimCard.propTypes = {
  tokenName: PropTypes.string.isRequired,
  Logo: PropTypes.element.isRequired,
  about: PropTypes.string.isRequired,
  tokenAddress: PropTypes.string.isRequired,
  contractAddress: PropTypes.string.isRequired,
  dexAddress: PropTypes.string.isRequired,
  devAddress: PropTypes.string.isRequired,
  dexName: PropTypes.string.isRequired,
  tokenSymbol: PropTypes.string.isRequired,
  tokenDecimals: PropTypes.number.isRequired,
  tokenTotalSupply: PropTypes.number.isRequired,
  tokenSupplyLiquidity: PropTypes.number.isRequired,
  tokenSupplyUSD: PropTypes.number.isRequired,
  tokenUnsoldTokens: PropTypes.string.isRequired,
  tokenCover: PropTypes.string.isRequired,
  website: PropTypes.string,
  telegram: PropTypes.string,
  twitter: PropTypes.string
}

export default ClaimCard
