/* eslint-disable import/no-anonymous-default-export */

export default {
    multicallAddress: {
        1: '0x9F454851093A664f78044DdEE41bD1E08797E440',
        56: '0xaD1c9bCe385293Ec09b3Fb054fadDe70fd88ebc5',
        11155111: '0x9F454851093A664f78044DdEE41bD1E08797E440',
        137: '0x1156256625faa48840Aea2Cd6f96418FDB4370fB',
        8453: '0x112284c273Ce5a01d43938312727d649a6Dbf569',
        10321: '0x46Cb98C22740A589Df59e3Aa0519913D8c811C42'
    },
    factoryAddress: {
        1: '0x34563DaCf93fA3df3A191ceC50d8E83B2E540348',
        56: '0xA3Fc96C283F647F4d3Bd8D346118d4F2905b7355',
        11155111: '0x46DC9Bd09ab89db33c3Df72F3Adf27Dba4A37d80',
        137: '0xE1C7759AebCd688e5f23B7C7A96a2dB87582A9Ae',
        8453: '0x031007b208AE73e4D63Ede6396128B449fE5aB5B',
        10321: '0x7091f5589a870d2655d89FD818C470b3070d5819'
    },
    contractAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        11155111: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
        10321: '0x0000000000000000000000000000000000000000',
    },
    WETHAddress: {
        1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        11155111: '0xb16F35c0Ae2912430DAc15764477E179D9B9EbEa',
        137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        8453: '0x4200000000000000000000000000000000000006',
        10321: '0x76132140d11907acd99c41397d890bf6b5258c4d',
    },
    routerAddress: {
        1: {
            'Uniswap': '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', 
            // 'Pancakeswap': '0xEfF92A263d31888d860bD50809A8D171709b7b1c',
            // 'Sushiswap': '0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F'
        },
        // 56: {
        //     'Uniswap': '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24',
        //     'Pancakeswap': '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        //     'Sushiswap': '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506'
        // },
        11155111: {'Uniswap': '0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008'},
        // 137: {'Uniswap': '0xedf6066a2b290C185783862C7F4776A2C8077AD1'},
        8453: {
            'Uniswap': '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24',
            // 'Pancakeswap': '0x8cFe327CEc66d1C090Dd72bd0FF11d690C33a2Eb',
            // 'Sushiswap': '0x6BDED42c6DA8FBf0d2bA55B2fa120C5e0c8D7891',
            // 'Baseswap': '0x327Df1E6de05895d2ab08513aaDD9313Fe505d86'
        },
        10321: {'Hotkeyswap': '0xb67769829fbb3f32c709329A8137ec471339ABA7'},
        
    },
    defaultAddress: {
        1: '0x0000000000000000000000000000000000000000',
        56: '0x0000000000000000000000000000000000000000',
        11155111: '0x0000000000000000000000000000000000000000',
        137: '0x0000000000000000000000000000000000000000',
        8453: '0x0000000000000000000000000000000000000000',
        10321: '0x0000000000000000000000000000000000000000',
    },
}